import React from "react";
import Img from "gatsby-image";
import { useStaticQuery, graphql, Link } from "gatsby";
import dayjs from "dayjs";
import { PATH } from "~/constant/path";
import { breakPoints } from "~/utils/variables";
import { colors } from "~/utils/colors";

export const InsightSidebar: React.FC = () => {
  const { events, articles } = useStaticQuery(graphql`
    query {
      events: allContentfulEvent(limit: 1, sort: { fields: date, order: DESC }) {
        edges {
          node {
            endedAt
            receptionStatus
            contentful_id
            ogpImage {
              fluid(maxWidth: 300) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
      articles: allContentfulInsightArticle(
        filter: { ranking: { lt: 11 } }
        sort: { order: ASC, fields: ranking }
        limit: 10
      ) {
        edges {
          node {
            contentful_id
            path
            id
            title
            cover {
              fixed(width: 85, height: 60) {
                ...GatsbyContentfulFixed
              }
            }
          }
        }
      }
    }
  `);
  const event = events.edges.map((edge) => edge.node)[0];

  return (
    <>
      <style jsx>{`
        .eventImg {
          position: relative;
        }
        .ranking {
          margin-top: 30px;
        }
        .rankingTitle {
          font-size: 2rem;
          font-weight: bold;
        }
        .rankings {
          list-style: none;
          margin: 30px 0 0;
        }
        .rankingItem {
          position: relative;
        }
        .rankingItem + .rankingItem {
          margin-top: 15px;
          padding-top: 15px;
          border-top: 1px dotted #ccc;
        }
        .articleContainer {
          display: flex;
          align-items: flex-start;
        }
        .articleImg {
          width: 85px;
          position: relative;
        }
        .articleTitle {
          width: calc(100% - 85px);
          padding-left: 15px;
          font-size: 1.3rem;
          font-weight: 500;
          line-height: 20px;
        }
        .articleTitle :global(a) {
          color: ${colors.defaultText};
        }
        .articleTitle :global(a:hover) {
          color: ${colors.orange};
        }
        .rankingNumber {
          border-radius: 24px;
          width: 24px;
          height: 24px;
          line-height: 24px;
          background-color: #333;
          left: 5px;
          top: 5px;
          color: ${colors.white};
          font-size: 1.5rem;
          font-weight: bold;
          text-align: center;
          position: absolute;
          z-index: 1;
        }
        .rankingNumber1 {
          background-color: #eac818;
        }
        .rankingNumber2 {
          background-color: #b5b5b5;
        }
        .rankingNumber3 {
          background-color: #c16e5b;
        }
        .wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        @media (${breakPoints.sp}) {
          .wrapper {
            padding: 0 30px;
          }
        }
        @media (${breakPoints.minPc}) {
          .eventImg {
            border: 1px solid ${colors.gray3};
            padding: 8px;
          }
        }
      `}</style>
      <div className="wrapper">
        {event.length !== 0 && dayjs().isBefore(dayjs(event.endedAt)) && (
          <div className="eventImg">
            <Link to={`${PATH.EVENTS_SEMINAR.to}/${event.contentful_id}`}>
              <Img fluid={event.ogpImage.fluid} />
            </Link>
          </div>
        )}
        <div className="ranking">
          <h2 className="rankingTitle">ランキング</h2>
          <ul className="rankings">
            {articles.edges.map((edge, index) => {
              const { contentful_id, path, title } = edge.node;
              const pagePath = `${path || `${PATH.INSIGHT.to}/${contentful_id}`}`;

              return (
                <li key={`InsightSidebar_articles_${edge.node.contentful_id}`} className="rankingItem">
                  <div className="articleContainer">
                    <div className={`articleImg`}>
                      <div className={`rankingNumber rankingNumber${index + 1}`}>{index + 1}</div>
                      <Link to={pagePath}>
                        <Img fixed={edge.node.cover?.fixed} objectFit="cover" objectPosition="50% 50%" />
                      </Link>
                    </div>
                    <h3 className="articleTitle">
                      <Link to={pagePath}>{title}</Link>
                    </h3>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};
